import axios from 'axios';
const token= localStorage.getItem('access_token');
// Create an Axios instance
const api = axios.create({
  baseURL: 'https://api.paisebnao.com/v1',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Function to register a user
export const registerUser = async (userData ) => {
  try {
    const response = await api.post('/user/registration/', userData);
    return response.data;
  } catch (error) {
    console.error('Error registering user:', error);
    throw error;
  }
};
// Function to Forget Pasword a user
export const forgetPassword = async (email_id ) => {
  try {
    const response = await api.post(
      '/forgot-password/',
      { email_id }, 
      {
        headers: {
          'Accept': 'application/json, text/plain, */*',
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error sending forget password request:', error);
    throw error;
  }
};

// Define the OTP verification function
export const verifyOtp = async (otp, email_id) => {
  try {
    const response = await axios.post(
      'https://api.paisebnao.com/v1/verify-otp/',
      { otp, email_id }, 
      {
        headers: {
          'Accept': 'application/json, text/plain, */*',
        }
      }
    );
    
    return response.data;
  } catch (error) {
    console.error('Error verifying OTP:', error);
    throw error;
  }
};

// Define the OTP verification after signup function
export const verifyOtpAfterSignup = async (otp, token, request_id) => {
  try {
    const response = await axios.post(
      'https://api.paisebnao.com/v1/verify-otp-after-signup/',
      { otp, request_id }, 
      {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Authorization': `Bearer ${token}`, 
        }
      }
    );
    
    return response.data;
  } catch (error) {
    console.error('Error verifying OTP:', error);
    throw error;
  }
};


// function for create order api 

export const createOrder = async (amount,membership_plan_id, token) => {
  try {
    const response = await axios.post(
      'https://api.paisebnao.com/v1/create-order/',  
     { amount, membership_plan_id} ,
      {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Authorization': token,  
        }
      }
    );
    
    return response.data;  
  } catch (error) {
    console.error('Error creating order:', error);
    throw error;  
  }
};

export const PaymentLinkApi = async (payment_session_id, token) => {
  try {
    const response = await axios.post(
      'https://api.paisebnao.com/v1/payment-link/',  
      
     { payment_session_id} ,
      {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Authorization': token,  
        }
      }
    );
    
    return response.data;  
  } catch (error) {
    console.error('Error creating order:', error);
    throw error;  
  }
};




// function for create order api 


export const editProfileContainer = async (first_name, phone_number, token) => {
  try {
    const response = await axios.patch(
      'https://api.paisebnao.com/v1/edit-profile/', 
      {
        first_name,  
        phone_number,
      },
      {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Authorization': token,  
        }
      }
    );

    return response.data;  
  } catch (error) {
    console.error('Error editing profile:', error);  
    throw error;  
  }
};






export const setNewPassword = async (email_id, new_password, token) => {
  try {
    const response = await axios.post(
      'https://api.paisebnao.com/v1/new-password/', 
      { email_id, new_password },  
      {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Authorization': token,  
        }
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error setting new password:', error);
    throw error;
  }
};


// Function for fetching dashboard data
export const dashboardApi = async () => {
  try {
    const response = await axios.get('https://api.paisebnao.com/v1/dashboard/', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      params: {
        link: 'https://www.youtube.com/watch?v=3WEkV_ciEYQ'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching dashboard data:', error);
    throw error;
  }
};


export const getUpiApi = async () => {
  try {
    const response = await axios.get('https://api.paisebnao.com/v1/api/get-upi/', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token, 
      },
     
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching UPI data:', error);
    throw error;
  }
};


// Function for fetching dashboard data
export const PartnerApi = async () => {
  try {
    const response = await axios.get('https://api.paisebnao.com/v1/our-partners/', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      params: {
        link: 'https://www.youtube.com/watch?v=3WEkV_ciEYQ'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching dashboard data:', error);
    throw error;
  }
};

// Function for GetMembership dashboard data
export const handleGetMembership = async () => {
  try {
    const response = await axios.get('https://api.paisebnao.com/v1/get-membership/', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
     
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching dashboard data:', error);
    throw error;
  }
};



export const addReward = async (id) => {
  try {
    const response = await axios.post('https://api.paisebnao.com/v1/add-reward/', {
      dashboard_id: id
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      }
    });
    return response;
  } catch (error) {
    console.error('Error adding reward:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const fetchPartnerData = async () => {
  try {
    const response = await axios.get('https://api.paisebnao.com/v1/our-partners/', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
     
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching partner data:', error.message);
    throw error;
  }
};



export const fetchUserdetail = async () => {
  try {
    const response = await axios.get('https://api.paisebnao.com/v1/get-user/', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
     
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching partner data:', error.message);
    throw error;
  }
};




// Function to login a user
export const loginUser = async (email, password) => {
  try {
    const response = await api.post('/user/login/', {
      email: email,
      login_type: 'email',
      password: password,
    });
    return response.data;
  } catch (error) {
    console.error('Login failed:', error);
    throw error;
  }
};

// Function to contact us 
export const ContactUs = async (message, name, email) => {
  try {
    const response = await api.post('contact-us/', {
      message: message,
      name: name,
      email: email,
    });
    return response.data;
  } catch (error) {
    console.error('error in contact detail', error);
    throw error;
  }
};


// Function to Withdrawal request
export const withdrawalrequest = async (upi_id, amount, token) => {
  try {
    const response = await api.post(
      'add-withdraw/',
      {
        upi_id: upi_id,
        amount: amount,
      },
      {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Authorization': token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error in withdrawal request:', error);
    throw error;
  }
};




// ifsc code api


export const fetchIFSCDetails = async (ifscCode) => {
  const response = await fetch(`https://ifsc.razorpay.com/${ifscCode}`);
  if (!response.ok) {
    throw new Error('Invalid IFSC code');
  }
  return response.json();
};

export const submitBankDetails = async (accountNumber, ifscCode, accountHolderName, token) => {
  try {
    const response = await api.post(
      '/bank-details/',
      {
        account_number: accountNumber,
        ifsc: ifscCode,
        account_holder: accountHolderName,
      },
      {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Authorization': token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error('Bank details submission failed:', error);
    throw error;
  }
};


export const getBankDetails = async (token) => {
  try {
    const response = await api.get(
      '/bank-details/',  
      {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Authorization': token,
        },
      }
    );
    
    return response;
  } catch (error) {
    console.error('Failed to fetch bank details:', error);
    throw error; 
  }
};






// api.js


export const fetchIFSCSuggestions = async (inputIFSC) => {
  try {
    const response = await axios.get(
      `https://ifsc.razorpay.com/${inputIFSC}` 
    );
    return response.data; 
  } catch (error) {
    console.error('Error fetching IFSC suggestions:', error);
    throw error;
  }
};

