import React, { useState } from 'react';
import './LoginScreen.css';
import paisebnaologo from '../../../assets/images/paisebnaologo.png';
import googlebtn from '../../../assets/images/googlebtn.png';
import emailicon from '../../../assets/images/email.png';
import lock from '../../../assets/images/lock.png';
import eyeoff from '../../../assets/images/eye-off.png';
import eyeon from '../../../assets/images/eye-on.png';
import Header from '../common/header';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../../../api/api'; 
import Container from '../../container';
import GoogleAuth from '../../pages/GoogleAuth';
import ToastMsg from '../common/ToastMsg.js'; 

const LoginScreen = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [emailOrPhone, setEmailOrPhone] = useState('');
  const [password, setPassword] = useState('');
  const [inputError, setInputError] = useState('');
  const [loginError, setLoginError] = useState('');
  
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^[0-9]{10}$/; 
    return phoneRegex.test(phone);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setEmailOrPhone(value);

    setInputError('');

    if (value.length > 154) {
      setInputError('Input must be 154 characters or less.');
    }
  };

  const toggleVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (validateEmail(emailOrPhone)) {
      try {
        const response = await loginUser(emailOrPhone, password);
        console.log(response, "response");

        localStorage.setItem('access_token', response?.access_token);

        if (!localStorage.getItem('access_token')) {
            throw new Error('Access token is not defined');
        } else {
            ToastMsg.success('Login successful!'); 
            navigate('/dashboard');
        }
      } catch (error) {
        console.error('Login failed:', error);
        setLoginError('Invalid credentials. Please enter correct ID and password.');
        ToastMsg.error('Login failed. Invalid credentials.'); 
      }
    }
    // If phone number is entered
    else if (validatePhoneNumber(emailOrPhone)) {
      try {
        const response = await loginUser(emailOrPhone, password);
        console.log(response, "response");

        localStorage.setItem('access_token', response?.access_token);

        if (!localStorage.getItem('access_token')) {
            throw new Error('Access token is not defined');
        } else {
            ToastMsg.success('Login successful!'); 
            navigate('/dashboard');
        }
      } catch (error) {
        console.error('Login failed:', error);
        setLoginError('Invalid credentials. Please enter correct ID and password.');
        ToastMsg.error('Login failed. Invalid credentials.'); 
      }
    }
    else {
      setInputError('Please enter a valid email or phone number.');
    }
  };

  return (
    <Container>
      <div className="">
        <div className='flex-row mt-10'>
          <Header />
        </div>
        <img src={paisebnaologo} alt="Logo" className="logo mt-20" />

        <h2 className="subtitle">Log In</h2>
        <div className="input-container">
          <form onSubmit={handleLogin}>
            <div className="input-wrapper mb-20">
              <img src={emailicon} alt="Email Icon" className="icon-size icon-inside-input lock-icon" />
              <input
                type="text"
                placeholder="Enter your email or phone number"
                className="input-field"
                value={emailOrPhone}
                onChange={handleInputChange}
                required
                autoComplete="off"
              />
            </div>
            {inputError && <div className="error-message">{inputError}</div>}

            <div className="input-wrapper">
              <img src={lock} alt="Password Icon" className="icon-size icon-inside-input " />
              <input
                type={passwordVisible ? "text" : "password"}
                placeholder="Enter your password"
                className="input-field"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                maxLength={4}
                required
              />
              <img
                src={passwordVisible ? eyeon : eyeoff}
                alt="Toggle Visibility"
                className="visibility-icon icon-size icon-inside-input"
                onClick={toggleVisibility}
              />
            </div>

            {loginError && <div className="error-message">{loginError}</div>}

            <p className="terms-text fontstyle">
              By going forward you agree to <a href="/termsandcondition" className="red-link underline">Terms & Conditions</a>
            </p>
            <a href="/enter-email" className="fontstyle bolder red-link">Forgot Password?</a>
            <button type="submit" className="login-button-login bolder">Login</button>
          </form>
        </div>

      </div>
    </Container>
  );
};

export default LoginScreen;
